import { FC } from 'react';
import { IconProps } from '.';

export const IconAddColumn: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_19261_89692)">
        <path
          d="M6.47695e-07 7L8.15 7L5.55 4.4L7 3L12 8L7 13L5.55 11.6L8.15 9L5.60272e-07 9L6.47695e-07 7ZM11 -2.18557e-07L14 -8.74228e-08C14.55 -6.33815e-08 15.0208 0.195833 15.4125 0.5875C15.8042 0.979167 16 1.45 16 2L16 14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16L11 16L11 14L14 14L14 2L11 2L11 -2.18557e-07Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19261_89692">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
