'use client';
import { useEffect, useRef } from 'react';
import { MagicTableRefHandles } from '..';
import { ColDef } from 'ag-grid-community';
import { generateEmptyColumns, generateEmptyRows } from './helpers';

export const useMagicTable = () => {
  const tableRef = useRef<MagicTableRefHandles>(null);

  // Load empty table on mount
  useEffect(() => {
    // Show full loading skeletons
    // const { columns, rows } = generateEmptyLoadingRows(26, <Skeleton className="mt-2" />);
    // updateColumnDefs(columns);
    // updateRowDefs(rows);

    // Show empty table
    updateColumnDefs(generateEmptyColumns(26));
    updateRowDefs(generateEmptyRows(100));
  }, []);

  const updateColumnDefs = (newColDefs: ColDef[]) => {
    tableRef.current?.updateColumnDefs(newColDefs);
  };

  const updateRowDefs = (newRowData: unknown[]) => {
    tableRef.current?.updateRowData(newRowData);
  };

  const getTableData = () => {
    const result = tableRef.current?.getTableData?.() ?? [];
    return result;
  };

  const exportAsCSV = () => {
    tableRef.current?.exportAsCSV();
  };

  return { tableRef, updateColumnDefs, updateRowDefs, getTableData, exportAsCSV } as const;
};
