import { FILE_TYPES } from '@unique/shared-library';
import cn from 'classnames';
import { forwardRef, useMemo } from 'react';
import { FileRejection, FileWithPath, useDropzone, Accept } from 'react-dropzone';

type DropzoneProps = {
  additonalClassname?: string;
  onDrop: (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => void;
  onError: (error: Error) => void;
  accept: Accept;
  wrapperClassname?: string;
  maxFiles?: number;
  maxSize?: number;
  children?: React.ReactNode;
};

// This is a workaround to add the correct file type to the file object if it is missing.
// Usually the file type is defined but we discovered a very inconsistent bug where for some 
// users the file type is missing when uploading a md file.
// The issue is coming from the react-dropzone library and can be tracked here:
// https://github.com/react-dropzone/react-dropzone/issues/1368
const addMissingFileType = (files: FileWithPath[]): FileWithPath[] => {
  return files.map((file) => {
    if (file.type) return file;
    const fileEnding = file.name.split('.').pop();
    if (fileEnding) {
      const mimeTypes = FILE_TYPES[fileEnding as keyof typeof FILE_TYPES];
      return new File([file], file.name, {
        type: mimeTypes[0] ?? '',
      });
    }
    return file;
  });
};

export const Dropzone = forwardRef<HTMLElement, DropzoneProps>((props, ref) => {
  const {
    additonalClassname,
    onDrop,
    onError,
    accept,
    wrapperClassname,
    maxFiles = 50,
    maxSize,
    children,
  } = props;

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, isFocused } =
    useDropzone({
      accept,
      maxFiles,
      maxSize,
      onDrop: (acceptedFiles, fileRejections) => {
        onDrop(addMissingFileType(acceptedFiles), fileRejections);
      },
      onError,
      multiple: maxFiles > 1,
    });

  const className = useMemo(
    () => ({
      ...(isFocused ? { '!bg-surface !text-on-secondary': true } : {}),
      ...(isDragActive ? { '!bg-secondary-variant !text-black': true } : {}),
      ...(isDragAccept ? { '!bg-secondary-variant !text-black': true } : {}),
      ...(isDragActive ? { '!bg-surface !text-black': true } : {}),
      ...(isDragAccept ? { '!bg-success-dark !text-black': true } : {}),
      ...(isDragReject ? { '!bg-error-dark !text-white': true } : {}),

      ...(additonalClassname ? { [additonalClassname]: true } : {}),
    }),
    [isFocused, isDragActive, isDragAccept, isDragReject, additonalClassname],
  );

  return (
    <section
      ref={ref}
      className={cn('dropzone-container mb-4 h-full px-0 xl:mb-0', wrapperClassname)}
    >
      <div
        {...getRootProps({
          className: cn(className),
        })}
      >
        <input {...getInputProps()} id="dropzone" />
        {children}
      </div>
    </section>
  );
});

Dropzone.displayName = 'Dropzone';
