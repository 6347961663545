'use client';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef,
  MenuItemDef,
  RowSelectedEvent,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import './styles/ag-theme-unique.css'; // Custom Theme applied to the Data Grid
import { idColumn } from './utils/helpers';
import { MenuModule, ModuleRegistry } from 'ag-grid-enterprise';

ModuleRegistry.registerModules([MenuModule]);

export type CellEditing = {
  value: string | number;
  columnId: string;
  rowId: number | null;
};

export interface MagicTableResult {
  position: number;
  rowData?: {
    columnId: string;
    value: unknown;
  }[];
}

interface MagicTableProps {
  onRowSelected?: (selected: unknown) => void;
  cellEditingStart?: (event: CellEditing) => void;
  cellEditingEnd?: (event: CellEditing) => void;
  className?: string;
}
export interface MagicTableRefHandles {
  updateColumnDefs: (newColDefs: ColDef[]) => void;
  updateRowData: (newRowData: unknown[]) => void;
  getTableData: () => MagicTableResult[];
  exportAsCSV: () => void;
}

const defaultColDef: ColDef = {
  flex: 1,
  sortable: false,
  minWidth: 200,
  editable: true,
  wrapText: true,
  autoHeight: true,
  cellClass: 'custom-ag-cell',
  context: false,
};

const Table = forwardRef<MagicTableRefHandles, MagicTableProps>((props, ref) => {
  const { onRowSelected, cellEditingStart, cellEditingEnd, className = 'h-[73vh]' } = props;
  const [rowData, setRowData] = useState<unknown[]>([]);

  const agGridRef = useRef<AgGridReact>(null);

  const [colDefs, setColDefs] = useState<ColDef[]>([]);

  const updateColumnDefs = (newColDefs: ColDef[]) => {
    setColDefs(newColDefs);
  };

  const updateRowData = (newRowData: unknown[]) => {
    setRowData(newRowData);
  };

  const handleSelect = (event: RowSelectedEvent) => {
    onRowSelected?.(event.data);
  };

  useImperativeHandle(ref, () => ({
    updateColumnDefs,
    updateRowData,
    getTableData,
    exportAsCSV,
  }));

  const autoSizeStrategy = useMemo<
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy
  >(() => {
    return {
      type: 'fitGridWidth',
      defaultMinWidth: 100,
    };
  }, []);

  const getCellValue = (event: CellEditingStartedEvent | CellEditingStoppedEvent) => {
    return event.api.getCellValue({ colKey: event.column, rowNode: event.node });
  };

  const onCellEditingStarted = useCallback((event: CellEditingStartedEvent) => {
    const cellValue = getCellValue(event);
    cellEditingStart?.({
      value: cellValue,
      columnId: event.column.getId(),
      rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
    });
  }, []);

  const onCellEditingStopped = useCallback((event: CellEditingStoppedEvent) => {
    const cellValue = getCellValue(event);
    cellEditingEnd?.({
      value: cellValue,
      columnId: event.column.getId(),
      rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
    });
  }, []);

  const getTableData = (): MagicTableResult[] => {
    const rowCount = agGridRef.current?.api.getDisplayedRowCount() || 0;

    return Array.from({ length: rowCount }, (_, i) => {
      const row = agGridRef.current?.api.getDisplayedRowAtIndex(i)?.data || {};
      const { id, ...rest } = row;

      if (Object.keys(rest).length > 0 && id !== undefined) {
        const rowData = Object.entries(rest).map(([columnId, value]) => ({ columnId, value }));
        return { position: id, rowData };
      }
      return null;
    }).filter(Boolean) as MagicTableResult[];
  };

  const exportAsCSV = () => {
    agGridRef.current?.api.exportDataAsCsv();
  };

  const getContextMenuItems = (): (string | MenuItemDef)[] => {
    return [
      {
        name: 'View History',
      },
    ];
  };

  return (
    <div className={`ag-theme-quartz ag-theme-unique ${className}`}>
      <AgGridReact
        ref={agGridRef}
        debug={false}
        rowData={rowData}
        columnDefs={[idColumn, ...colDefs]}
        defaultColDef={defaultColDef}
        autoSizeStrategy={autoSizeStrategy}
        rowSelection={{
          mode: 'multiRow',
          checkboxes: false,
          headerCheckbox: false,
          enableClickSelection: true,
        }}
        stopEditingWhenCellsLoseFocus={true}
        onRowSelected={(event) => handleSelect(event)}
        onCellEditingStarted={(event) => onCellEditingStarted(event)}
        onCellEditingStopped={(event) => onCellEditingStopped(event)}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  );
});

export const MagicTable = memo(Table);
