import { ColDef, ColumnHeaderClickedEvent } from 'ag-grid-community';
import { ReactNode } from 'react-markdown';

export const idColumn: ColDef = {
  field: 'id',
  headerName: '',
  pinned: 'left',
  rowDrag: false,
  maxWidth: 40,
  lockPosition: 'left',
  lockVisible: false,
  wrapText: false,
  autoHeight: false,
  cellStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F5',
  },
};

export function generateExcelHeaders(columnCount: number): string[] {
  const headers: string[] = [];

  for (let i = 0; i < columnCount; i++) {
    headers.push(getExcelColumnName(i));
  }

  return headers;
}

function getExcelColumnName(index: number): string {
  let columnName = '';
  let remainder: number;

  while (index >= 0) {
    remainder = index % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    index = Math.floor(index / 26) - 1;
  }

  return columnName;
}

export const generateEmptyColumns = (numberOfColumns = 26) => {
  const emptyColumns = generateExcelHeaders(numberOfColumns).map((header) => {
    return {
      headerName: header,
      field: header,
    };
  });
  return emptyColumns;
};

export const generateEmptyRows = (rowCount: number) => {
  const emptyRows = Array.from({ length: rowCount }, (_, index) => {
    return { id: index + 1 };
  });
  return emptyRows;
};

export const generateEmptyLoadingRows = (
  columnCount = 26,
  loadingComponent: string | ReactNode,
) => {
  const headersGenerated: string[] = [];
  const columns = generateExcelHeaders(columnCount).map((header) => {
    headersGenerated.push(header);
    return {
      headerName: header,
      headerClass: '',
      field: header,
      sortable: false,
      minWidth: 200,
      editable: false,
      cellRenderer: () => loadingComponent,
    };
  });

  const emptyRows = headersGenerated.map((header, i) => {
    return {
      id: i + 1,
      field: header,
    };
  });

  return { columns: columns, rows: emptyRows };
};

/**
 *
 * @param colDefs AGGrid Column definitions
 * @param event the Column header click event
 * @returns a new column definition
 */
export const highlightHeaderColumnCells = (colDefs: ColDef[], event: ColumnHeaderClickedEvent) => {
  let columns: ColDef[] = colDefs;

  const found = removeHighlights(colDefs);

  // Deselect selected column if any
  if (found.length > 0) {
    columns = found;
  }

  const newColDefs = columns.map((colDef) => {
    if (colDef.field === event.column.getId()) {
      // Remove highlight if already present
      if (colDef.cellClass?.toString().includes('highlight-cells')) {
        return {
          ...colDef,
          cellClass: colDef.cellClass.toString().replace('highlight-cells', ''),
        };
      }
      // Add highlight if not present
      return {
        ...colDef,
        cellClass: 'highlight-cells',
      };
    }
    return colDef;
  });
  return newColDefs;
};

/**
 *
 * @param colDefs AGGrid Column definitions
 * @returns a new column definition
 */
export const removeHighlights = (colDefs: ColDef[]) => {
  let newColumns: ColDef[] = [];
  const foundColumns = colDefs.find((colDef) =>
    colDef.cellClass?.toString().includes('highlight-cells'),
  );
  if (foundColumns) {
    newColumns = colDefs.map((colDef) => {
      if (colDef?.cellClass?.toString().includes('highlight-cells')) {
        return {
          ...colDef,
          cellClass: colDef.cellClass.toString().replace('highlight-cells', ''),
        };
      }
      return colDef;
    });
  }
  return newColumns;
};
